// libraries
import React, { useState, useEffect } from 'react';
import 'normalize.css';
import { graphql } from 'gatsby';
import styled from 'styled-components';
// components
import { SEO } from 'components/SEO';
import { GlobalGridVars, Container, BREAKPOINT } from 'components/UI/Grid';
import { GlobalStyles } from 'components/UI/GlobalStyles';
import { Layout } from 'components/Layout';
import { AuthorBlock } from 'components/Blog/AuthorBlock';
import { Card } from 'components/Blog/Card';
import { Button } from 'components/UI/Button';
import { BackLink } from 'components/Blog/BackLink';
// helpers
import { sortPost } from 'helpers/blog';

const AuthorPage = ({ data, pageContext }) => {
    const authorsPost = data.allWpPost.nodes.filter((obj) => obj.postAuthor.author?.id === pageContext.id);
    const { currentAuthor } = pageContext;
    const postsPerView = 9;
    const countUploadedPosts = 6;
    const sortedPost = sortPost(-1, authorsPost);
    const [slicedPostCount, setSlicePostCount] = useState(postsPerView);
    const [showButtonMore, setShowButtonMore] = useState(sortedPost.length > postsPerView + 1);
    const avgSpeedReading = 1500;

    useEffect(() => {
        setSlicePostCount(postsPerView);
        setShowButtonMore(sortedPost.length > postsPerView);
    }, [postsPerView]);

    const moreButtonHandler = (e) => {
        e.target.blur();

        if (authorsPost.length <= slicedPostCount + postsPerView) {
            setShowButtonMore(false);
        }

        setSlicePostCount(slicedPostCount + countUploadedPosts);
    };

    return (
        <Layout pageType={pageContext.pageType}>
            <GlobalStyles />
            <GlobalGridVars />
            <StyledContainer>
                <BackLink link="/blog/" />
                <AuthorBlock
                    variant="authorPage"
                    name={currentAuthor.title}
                    description={currentAuthor.content}
                    slug={currentAuthor.slug}
                    avatar={currentAuthor.postAuthorAvatar.avatar}
                />
                <AuthorsPostsTitle>Articulos</AuthorsPostsTitle>
                <AuthorsPostsFeed>
                    {authorsPost &&
                        authorsPost
                            .slice(0, slicedPostCount)
                            .map(({ title, databaseId, content, excerpt, date, uri, featuredImage, categories }) => (
                                <Card
                                    key={databaseId}
                                    tags={categories}
                                    title={title}
                                    content={excerpt}
                                    date={date}
                                    url={uri}
                                    featuredImage={featuredImage && featuredImage.node.localFile.publicURL}
                                    timeReading={Math.round(content.length / avgSpeedReading)}
                                />
                            ))}
                </AuthorsPostsFeed>
                {showButtonMore && (
                    <StyledReadMore aria-label="Load more" onClick={(e) => moreButtonHandler(e)}>
                        Ver más
                    </StyledReadMore>
                )}
            </StyledContainer>
        </Layout>
    );
};
export default AuthorPage;

export const Head = ({
    pageContext: {
        currentAuthor: { title },
    },
}) => {
    const seoData = {
        title: `${title} - Plazo Blog`,
        metaDesc: `Ver todas las publicaciones de ${title} - Plazo Blog`,
        metaRobotsNofollow: 'follow',
        metaRobotsNoindex: 'index',
    };
    return <SEO data={seoData} />;
};

const StyledContainer = styled(Container)`
    margin-top: 72px;

    ${BREAKPOINT.md} {
        margin-top: 112px;
    }

    ${BREAKPOINT.xl} {
        max-width: 1132px;
        padding-bottom: 40px;
    }
`;

const AuthorsPostsTitle = styled.h2`
    font-size: 28px;

    ${BREAKPOINT.md} {
        font-size: 40px;
    }
`;

const AuthorsPostsFeed = styled.div`
    display: grid;
    grid-gap: 40px 24px;
    margin-top: 24px;

    ${BREAKPOINT.sm} {
        justify-content: space-between;
        margin: auto;
        padding: 0;
    }

    ${BREAKPOINT.md} {
        margin-top: 40px;
    }

    ${BREAKPOINT.xl} {
        grid-template-columns: repeat(3, 1fr);
    }
`;

const StyledReadMore = styled(Button)`
    display: block;
    width: 100%;
    max-width: 294px;
    margin: 40px auto 0;
    line-height: 1.3;
`;

export const query = graphql`
    query MyQuery($id: String!) {
        allWpPost(filter: { id: { ne: $id } }) {
            nodes {
                databaseId
                excerpt
                content
                title
                slug
                id
                uri
                authorDatabaseId
                date(formatString: "DD MMM, YYYY", locale: "ES")
                featuredImage {
                    node {
                        localFile {
                            publicURL
                        }
                    }
                }
                categories {
                    nodes {
                        id
                        name
                        slug
                    }
                }
                postAuthor {
                    author {
                        ... on WpPostAuthor {
                            id
                        }
                    }
                }
            }
        }
    }
`;
