// libraries
import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
// constants
import { COLORS } from 'constants/settings';
// components
import { BREAKPOINT } from 'components/UI/Grid';

export const AuthorBlock = ({ name, description, slug, variant, avatar }) => {
    return (
        <StyledAuthorBlock variant={variant}>
            <AuthorContent to={`/blog/${slug}`} variant={variant}>
                <AuthorAvatarWrapper variant={variant}>
                    <AuthorAvatar src={avatar.localFile.publicURL} alt={name} variant={variant} />
                </AuthorAvatarWrapper>
                {variant === 'authorPage' ? (
                    <AuthorNameTitle dangerouslySetInnerHTML={{ __html: name }} />
                ) : (
                    <AuthorName dangerouslySetInnerHTML={{ __html: name }} />
                )}
            </AuthorContent>
            <AuthorDescription dangerouslySetInnerHTML={{ __html: description }} />
        </StyledAuthorBlock>
    );
};

const StyledAuthorBlock = styled.div`
    margin: 40px 0;
    padding: 24px;
    background: ${COLORS.secondary};
    border-radius: 12px;

    ${BREAKPOINT.md} {
        margin: 72px 0;
    }

    ${({ variant }) => {
        if (variant === 'authorPage') {
            return css`
                padding: 32px 24px;
            `;
        }
    }}
`;

const AuthorNameTitle = styled.h1`
    margin: 8px 0 0;
    font-size: 34px;

    ${BREAKPOINT.md} {
        margin-top: 24px;
        font-size: 48px;
    }
`;

const AuthorName = styled.p`
    padding-left: 8px;
    font-weight: 500;
    font-size: 14px;

    ${BREAKPOINT.md} {
        font-size: 18px;
    }
`;

const AuthorContent = styled(Link)`
    display: flex;
    align-items: center;
    width: 100%;
    padding-bottom: 16px;
    color: ${COLORS.dark};

    ${({ variant }) => {
        if (variant === 'authorPage') {
            return css`
                display: block;
                padding-bottom: 24px;
            `;
        }
    }}
`;

const AuthorDescription = styled.div`
    color: ${COLORS.blogText};
    font-size: 14px;

    ${BREAKPOINT.md} {
        font-size: 16px;
    }
`;

const AuthorAvatarWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border: 1.6px solid ${COLORS.success};
    border-radius: 50%;

    ${BREAKPOINT.md} {
        width: 56px;
        height: 56px;
        border: 2.3px solid ${COLORS.success};
    }

    ${({ variant }) => {
        if (variant === 'authorPage') {
            return css`
                width: 56px;
                height: 56px;
                border: 2.3px solid ${COLORS.success};

                ${BREAKPOINT.md} {
                    width: 96px;
                    height: 96px;
                    border: 4px solid ${COLORS.success};
                }
            `;
        }
    }}
`;

const AuthorAvatar = styled.img`
    width: 33px;
    height: 33px;
    border-radius: 50%;

    ${BREAKPOINT.md} {
        width: 46px;
        height: 46px;
    }

    ${({ variant }) => {
        if (variant === 'authorPage') {
            return css`
                width: 46px;
                height: 46px;

                ${BREAKPOINT.md} {
                    width: 80px;
                    height: 80px;
                }
            `;
        }
    }}
`;
