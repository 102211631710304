// libraries
import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
// components
import { BREAKPOINT } from 'components/UI/Grid';
// constants
import { COLORS } from 'constants/settings';
// static
import arrow from 'assets/images/svg-icons/arrow.svg';

export const BackLink = ({ link }) => {
    return (
        <StyledBackLink to={link}>
            <img src={arrow} alt="" aria-hidden />
            <span>Volver al blog</span>
        </StyledBackLink>
    );
};

const StyledBackLink = styled(Link)`
    display: flex;
    align-items: center;
    color: ${COLORS.dark};
    font-weight: 500;
    font-size: 14px;
    line-height: 1.3;

    ${BREAKPOINT.md} {
        font-size: 18px;
    }

    img {
        padding-left: 18px;
        transform: rotate(180deg);
    }
`;
