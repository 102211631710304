export const sortPost = (activeTagId, newsList) => {
    if (activeTagId === -1) {
        return Array.from(newsList);
    }

    const sortedNews = [];

    newsList.forEach((news) => {
        news.categories?.nodes?.forEach((category) => {
            if (category.id === activeTagId) {
                sortedNews.push(news);
            }
        });
    });

    return sortedNews;
};
