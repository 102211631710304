// libraries
import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
// components
import { BREAKPOINT } from 'components/UI/Grid';
import { COLORS } from 'constants/settings';

export const Card = ({ variant, title, date, content, tags, timeReading, url, featuredImage }) => {
    const filteredTags = tags.nodes.filter((i) => i.name !== 'Blog');
    const tagsArr = filteredTags.map((node) => node.slug);
    const [urlWithoutSubcats, setUrlWithoutSubcats] = useState(url);

    useEffect(() => {
        setUrlWithoutSubcats((prevUrl) => {
            return tagsArr.reduce((acc, item) => {
                return acc.replace(`/${item}/`, '/');
            }, prevUrl);
        });
    }, [tagsArr]);

    return (
        <StyledPostCard variant={variant} to={urlWithoutSubcats}>
            <StyledPostCardImageBlock>
                <StyledPostCardImage image={featuredImage} />
            </StyledPostCardImageBlock>
            <StyledPostCardContent>
                <StyledPostCardContentBlock>
                    <header>
                        <StyledPostCardTag>{filteredTags.map((object) => object.name).join(' • ')}</StyledPostCardTag>
                        <StyledPostCardTitle dangerouslySetInnerHTML={{ __html: title }} />
                        <StyledPostCardDate>
                            {date.replace(',', '')}&nbsp;•&nbsp;{timeReading} min de lectura
                        </StyledPostCardDate>
                    </header>
                    <StyledPostCardExcerpt dangerouslySetInnerHTML={{ __html: content }} />
                </StyledPostCardContentBlock>
            </StyledPostCardContent>
        </StyledPostCard>
    );
};

const StyledPostCardImage = styled.div`
    width: auto;
    height: 200px;
    background: ${COLORS.backdrop} no-repeat 50%;
    background-image: url(${({ image }) => image});
    background-size: cover;
    transition: width 0.1s ease, height 0.1s ease;
`;

const StyledPostCardContent = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;

    ${BREAKPOINT.xl} {
        min-height: 280px;
    }
`;

const StyledPostCardContentBlock = styled.div`
    position: relative;
    display: block;
    padding: 24px;
    color: ${COLORS.blogText};
`;

const StyledPostCardImageBlock = styled.div`
    position: relative;
    display: block;
    overflow: hidden;
    border-radius: 4px 4px 0 0;
`;

const StyledPostCardExcerpt = styled.div`
    margin-top: 16px;

    p {
        display: -webkit-box;
        overflow: hidden;
        font-size: 14px;
        line-height: 1.5;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;

        ${BREAKPOINT.xl} {
            font-size: 16px;
        }
    }
`;

const StyledPostCard = styled(Link)`
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background: ${COLORS.white} 50%;
    background-size: cover;
    border-radius: 4px;
    box-shadow: 0 2px 6px 0 rgb(0 0 0 / 10%);

    ${({ variant }) => {
        if (variant === 'main') {
            return css`
                :nth-last-child(n + 5),
                :only-child {
                    ${BREAKPOINT.md} {
                        flex-direction: row;
                        grid-area: 1 / 1 / 2 / 4;
                        max-width: 100%;

                        ${StyledPostCardImage} {
                            position: absolute;
                            width: 100%;
                            height: 100%;
                        }

                        ${StyledPostCardImageBlock} {
                            position: relative;
                            flex: 1 1 auto;
                            border-radius: 4px 0 0 4px;
                        }

                        ${StyledPostCardContent} {
                            flex: 0 1 357px;
                        }

                        ${StyledPostCardContentBlock} {
                            padding: 32px 40px;
                        }

                        ${StyledPostCardExcerpt} {
                            p {
                                -webkit-line-clamp: 7;
                            }
                        }
                    }
                }
            `;
        }
    }};

    &:hover {
        box-shadow: 0 0 1px rgb(39 44 49 / 10%), 0 3px 16px rgb(39 44 49 / 7%);
        transform: translate3d(0, -1px, 0);
        transition: all 0.3s ease;
    }
`;

const StyledSpan = styled.span`
    display: block;
    color: ${COLORS.pastelSecondary};
    font-size: 12px;
    line-height: 1.33;
    letter-spacing: 0.4px;
`;

const StyledPostCardTag = styled(StyledSpan)`
    margin-bottom: 8px;
`;

const StyledPostCardDate = styled(StyledSpan)`
    margin-top: 8px;
`;

const StyledPostCardTitle = styled.h2`
    color: ${COLORS.dark};
    font-weight: 500;
    font-size: 18px;
    line-height: 1.3;
`;
